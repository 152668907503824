import React from 'react';

import Layout from '../components/Layout';

const NotFoundPage = () => (
  <Layout title="404 Not Found">
    <h1>NOT FOUND</h1>
    <p>We are sorry, but the page You were looking for now longer exists.</p>
  </Layout>
);

export default NotFoundPage;
